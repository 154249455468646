<template>
  <div>
    <Header
      :title="$t('header.title.today')"
      show-profile-picture
      show-search-input
      :translations="headerTranslations"
    />
    <LoginOverlay/>
    <Footer/>
  </div>
</template>

<script setup lang="ts">
import type {HeaderTranslations} from "~/libs/interfaces/Translations";

const {t} = useI18n()


const headerTranslations: HeaderTranslations = {
  "months.1": t("header.date.months.1"),
  "months.2": t("header.date.months.2"),
  "months.3": t("header.date.months.3"),
  "months.4": t("header.date.months.4"),
  "months.5": t("header.date.months.5"),
  "months.6": t("header.date.months.6"),
  "months.7": t("header.date.months.7"),
  "months.8": t("header.date.months.8"),
  "months.9": t("header.date.months.9"),
  "months.10": t("header.date.months.10"),
  "months.11": t("header.date.months.11"),
  "months.12": t("header.date.months.12"),
  "search.placeholder": t("feed.search_placeholder"),
  "search.title": t("search.title"),
}
</script>
