<template>
  <div class="fixed inset-0 bg-black/40 backdrop-blur-sm z-[100] text-white overflow-auto">
    <div class="max-w-max sm:max-w-xl flex items-start sm:items-center flex-col gap-7 mx-5 sm:px-8 sm:mx-auto mt-[8vh] sm:mt-36">
      <div class="flex items-center gap-2 p-3.5 rounded-full bg-primary-green text-black text-xl">
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="Vector"
                d="M22.5938 9.71484L12 22.7578L1.40625 9.71484L7.69922 3H16.3008L22.5938 9.71484ZM14.7891 9L13.1016 4.5H10.8984L9.21094 9H14.7891ZM9.12891 10.5L12 20.5547L14.8711 10.5H9.12891ZM8.03906 4.5L3.83203 9H7.85156L9.53906 4.5H8.03906ZM3.67969 10.5L10.0312 18.3164L7.79297 10.5H3.67969ZM13.9688 18.3164L20.3203 10.5H16.207L13.9688 18.3164ZM20.168 9L15.9609 4.5H14.4609L16.1484 9H20.168Z"
                fill="black"/>
        </svg>
        <span>{{ $t('login.premium') }}</span>
      </div>
      <div class="sm:text-center text-3xl font-extrabold max-w-[85%] sm:max-w-none" v-html="$t('login.heading')">
      </div>
      <div class="sm:text-center text-base max-w-[85%] sm:max-w-none" v-html="$t('login.subheading')" />
      <div class="w-full sm:w-11/12 flex items-start flex-col self-start gap-2 mx-auto">
        <div class="max-w-[95%] sm:max-w-none sm:mx-auto">
        <LoginBenefit :title="$t('login.benefits[0]')"/>
        <LoginBenefit :title="$t('login.benefits[1]')"/>
        <LoginBenefit :title="$t('login.benefits[2]')"/>
        </div>

        <div class="mt-5 self-stretch  sm:w-[22rem] sm:mx-auto">
          <button
            v-if="!isLoaded"
            class="w-full py-6 px-4 rounded-full text-center bg-primary-green font-extrabold text-black"
          >
          <Loader ></Loader>
          </button>
          <button
            v-if="isLoaded"
            @click="login()"
            :disabled="loading"
            v-text="!loading ? $t('login.login_button') : $t('login.loading')"
            class="w-full py-6 px-4 rounded-full text-center bg-primary-green font-extrabold text-black"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const oidc = useOidc()
const loading = ref(false)
const isLoaded = ref(false)

const login = () => {
  if (loading.value) {
    return
  }
  loading.value = true
  oidc.login('/feed')
}

onMounted(() => {
  if (window) {
    isLoaded.value = true
  }
  console.log("window", window)
  document.body.classList.add("body-modal-enabled")
})
onUnmounted(() => {
  document.body.classList.remove("body-modal-enabled")
})

if (!oidc.isLoggedIn && window) {
  window.addEventListener("storage", (event) => {
    if (event.key !== 'loginStatus') {
      return;
    }
    if (event.newValue == 'true') {
      window.localStorage.removeItem('loginStatus')
      window.location.reload()
    }
  })
}
</script>
<style>
:root:has(.body-modal-enabled) {
  overflow:hidden;
}
</style>
